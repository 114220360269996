<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <crud-list
      v-else
      v-model="consultancys"
      :headers="headers"
      :slots="['item.telefone']"
      :actions="actions"
    >
      <template v-slot:btnCadastro>
        <v-row>
          <v-col>
            <v-btn
              color="green darken-1"
              dark
              class="rounded-lg"
              :loading="loadingSheet"
              @click="exportSheet"
            >
              <v-icon class="mr-2">mdi-file-excel</v-icon>

              <span style="font-size: 16px; font-weight: 600">Exportar</span>
            </v-btn>
          </v-col>
          <v-col>
            <router-link :to="`/${$user.get().role}/consultancy/form`">
              <v-btn color="success"> Nova consultoria </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.telefone`]="{ item }">
        {{ item.Celular ? celular(item.Celular) : ' - ' }}
      </template>
    </crud-list>
  </v-card>
</template>

<script>
import ConsultoriaService from '../../services/api/Consultoria'
import { celular } from '../../utils/masks'
import { JsonToExcel } from '../../utils/Worksheet'

export default {
  data() {
    return {
      actions: [
        {
          title: 'Editar consultoria',
          color: 'yellow darken-3',
          click: item =>
            this.$router.push({
              path: 'consultancy/form',
              query: {
                id: item.id,
              },
            }),
          icon: 'mdi-pencil',
        },
        {
          title: 'Excluir consultoria',
          color: 'red darken-3',
          click: item => this.deleteItem(item),
          icon: 'mdi-delete',
        },
      ],
      consultancys: [],
      loading: false,
      loadingSheet: false,
      headers: [
        {
          text: '',
          sortable: false,
          width: '80px',
          value: 'actions',
          align: 'left',
        },
        {
          text: 'Nome da consultoria',
          align: 'left',
          sortable: true,
          value: 'user.pessoa.pessoajuridica.NomeFantasia',
          width: 'auto',
        },
        {
          text: 'Email',
          align: 'left',
          sortable: true,
          value: 'user.email',
          width: 'auto',
        },
        {
          text: 'Telefone',
          align: 'left',
          sortable: true,
          value: 'user.pessoa.TelCel',
          width: 'auto',
        },
      ],
    }
  },
  created() {
    this.index()
  },

  methods: {
    celular,

    async index() {
      try {
        this.loading = true
        const response = await ConsultoriaService.list()

        this.consultancys = response.data.data
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao carregar consultorias',
        })
      } finally {
        this.loading = false
      }
    },

    exportSheet() {
      this.loadingSheet = true

      new JsonToExcel(
        this.consultancys,
        this.headers,
        'consultoria.xlsx',
        'Consultoria',
      )

      this.loadingSheet = false
    },

    deleteItem(item) {
      this.Swal.fire({
        icon: 'error',
        title: 'Excluir consultoria',
        text: 'Você tem certeza que deseja excluir consultoria?',
        showCancelButton: true,
        confirmButtonColor: '#f74242',
        confirmButtonText: 'SIM, EXCLUIR!',
        cancelButtonText: 'NÃO',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return ConsultoriaService.delete(item.id)
            .then(() => 1)
            .catch(reject => reject)
        },
        allowOutsideClick: () => !this.Swal.isLoading(),
      }).then(result => {
        if (result.value === 1) {
          this.loading = true
          this.index()
          this.Swal.fire({
            title: 'Sucesso',
            text: 'A consultoria foi excluída!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
          })
        }
      })
    },
  },
}
</script>
